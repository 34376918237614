<template>
  <!-- thumbnail area -->
  <div
    v-if="items.length > 1"
    class="media-gallery__thumbnails mx-lg-auto overflow-hidden"
  >
    <div ref="thumbnailsSlider" class="media-gallery__thumbnails-slider d-flex">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        class="col-4"
        @click="setCurrentSlide(index)"
      >
        <!-- thumbnail for videos -->
        <div
          v-if="item.component === 'media-gallery.video'"
          class="media-gallery__thumbnails-item position-relative rounded border border-2 cursor-pointer"
          :class="
            currentItemIndex === index
              ? 'border-primary media-gallery__thumbnails-item--active'
              : 'border-transparent'
          "
        >
          <strapi-image
            class="rounded"
            :image="asGalleryVideo(item).thumbnail"
            :alt="asGalleryVideo(item).thumbnail.alternativeText"
            fluid
          />

          <fa-icon
            :icon="faCirclePlay"
            class="media-gallery__thumbnails-icon position-absolute top-50 start-50 translate-middle text-primary"
          />
        </div>

        <!-- thumbnail for images -->
        <strapi-image
          v-else-if="item.component === 'media-gallery.image'"
          class="media-gallery__thumbnails-item w-100 rounded border border-2 cursor-pointer"
          :class="
            currentItemIndex === index
              ? 'border-primary media-gallery__thumbnails-item--active'
              : 'border-transparent'
          "
          :image="getStrapiImageThumbnail(item)"
          :alt="asGalleryImage(item).image.alternativeText"
          :alt-fallback="`Thumbnail ${asGalleryImage(item).image.id}`"
          fluid
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  StrapiImage,
  StrapiImageFormat,
  StrapiMediaGalleryItem,
} from '~/apollo/types/types';
import { useStrapiMediaTypes } from './features';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  props: {
    currentItemIndex: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array as PropType<Array<StrapiMediaGalleryItem>>,
      required: true,
    },
  },
  emits: ['thumbnail-clicked'],
  setup(props, { emit }) {
    const thumbnailsSlider = ref<HTMLElement>(null);
    const setCurrentSlide = (index) => emit('thumbnail-clicked', index);
    const { asGalleryImage, asGalleryVideo } = useStrapiMediaTypes();

    const getStrapiImageThumbnail = (item): StrapiImage | StrapiImageFormat => {
      const image = asGalleryImage(item);
      const thumbnail = image.image.formats.find(
        (format) => format.format === 'thumbnail',
      );

      return thumbnail != null ? thumbnail : image.image;
    };

    const watchCurrentIndexHandler = watch(
      () => props.currentItemIndex,
      (currentItemIndex, previousCurrentItemIndex) => {
        if (
          // when going forward - start sliding from the third element onwards
          ((currentItemIndex > previousCurrentItemIndex &&
            currentItemIndex > 1) ||
            // when going backwards - force sliding when the second element is reached
            // to make sure that this element is in the center
            (currentItemIndex < previousCurrentItemIndex &&
              currentItemIndex > 0)) &&
          // we want to make sure that we do not slide too far to the left
          currentItemIndex < props.items.length - 1
        ) {
          // we only want to keep the current item in the center of the thumbnail slider
          thumbnailsSlider.value.style.transform = `translate3d(-${
            (currentItemIndex - 1) * 33.333333
          }%, 0 ,0)`;
        }

        // we need to cover the edge case when going from the end to the start
        if (
          currentItemIndex === 0 &&
          previousCurrentItemIndex === props.items.length - 1
        ) {
          thumbnailsSlider.value.style.transform = `translate3d(-${
            currentItemIndex * 33.333333
          }%, 0 ,0)`;
        }

        // we need to cover the edge case when going from the start to the end
        if (
          previousCurrentItemIndex === 0 &&
          currentItemIndex === props.items.length - 1
        ) {
          // first we need to determine if there are two elements before the last one
          if (currentItemIndex - 2 >= 0) {
            // if it is the case, we need to slide to the third last element
            thumbnailsSlider.value.style.transform = `translate3d(-${
              (currentItemIndex - 2) * 33.333333
            }%, 0 ,0)`;
          } else {
            // else slide to the start
            thumbnailsSlider.value.style.transform = `translate3d(0, 0 ,0)`;
          }
        }
      },
    );

    onUnmounted(() => {
      watchCurrentIndexHandler();
    });

    return {
      faCirclePlay,

      thumbnailsSlider,
      setCurrentSlide,
      asGalleryVideo,
      asGalleryImage,
      getStrapiImageThumbnail,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.media-gallery {
  &__thumbnails {
    &-item {
      opacity: 0.5;
      transition:
        border-color 0.3s ease,
        opacity 0.3s ease;

      &--active,
      &:hover {
        opacity: 1;
      }
    }

    &-slider {
      margin: 0 -0.5rem;
      transition: transform 0.5s ease;

      > .col-4 {
        padding: 0 0.5rem;
      }
    }

    &-icon {
      font-size: 1.875rem;

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3.75rem;
      }
    }
  }
}
</style>
