<template>
  <div v-if="isSupportedPlatform" class="video-player">
    <div
      v-if="!isLoaded"
      ref="thumbnailContainer"
      class="position-relative cursor-pointer"
      @click="onThumbnailClicked"
    >
      <fa-icon
        class="video-player__play-icon position-absolute top-50 start-50 translate-middle text-primary"
        :icon="faPlayCircle"
      />
      <strapi-image :image="video.thumbnail" fluid />
    </div>

    <div
      class="video-player__wrapper position-relative overflow-hidden w-100"
      :class="{ 'video-player__wrapper--player-loaded': isLoaded }"
    >
      <lazy-media-gallery-video-youtube-video
        v-if="video.platform === YOUTUBE_VIDEO_PLATFORM"
        ref="videoComponent"
        :is-active="isActive"
        :video="video"
        :width="width"
        :height="height"
        @video-loaded="(value) => (isLoaded = value)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiMediaGalleryVideoItem } from '~/apollo/types/types';
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { YOUTUBE_VIDEO_PLATFORM } from '~/composables/video/useYoutube';
import type { LazyMediaGalleryVideoYoutubeVideo } from '#build/components';

export default defineComponent({
  name: 'MediaGalleryVideo',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object as PropType<StrapiMediaGalleryVideoItem>,
      required: true,
    },
  },
  setup(props) {
    const thumbnailContainer = ref<HTMLElement | undefined>(undefined);
    const { height, width } = useElementBounding(thumbnailContainer);
    const isSupportedPlatform = computed(() =>
      [YOUTUBE_VIDEO_PLATFORM].includes(props.video.platform),
    );

    const videoComponent = ref<InstanceType<
      typeof LazyMediaGalleryVideoYoutubeVideo
    > | null>(null);

    const onThumbnailClicked = async () => {
      await nextTick();

      if (videoComponent.value) {
        videoComponent.value.loadVideo();
      }
    };

    const isLoaded = ref(false);

    return {
      faPlayCircle,

      isSupportedPlatform,
      YOUTUBE_VIDEO_PLATFORM,
      thumbnailContainer,
      onThumbnailClicked,
      width,
      height,
      videoComponent,
      isLoaded,
    };
  },
});
</script>

<style lang="scss">
// we need a public style to apply style to iframe works
.video-player {
  &__wrapper {
    &--player-loaded {
      &:after {
        padding-top: 56.25%;
        display: block;
        content: '';
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.video-player {
  &__play-icon {
    font-size: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 3.75rem;
    }
  }
}
</style>
