// we need to import reference path of the youtube iframe API typings here because the
// devs did not exported any members on root level, hence we need to reference it here
// instead of a regular module import
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../node_modules/@types/youtube/index.d.ts" />

import type { StrapiMediaGalleryVideoItem } from '~/apollo/types/types';
import type { VideoFeatures } from './types';

const IFRAME_API_URL = 'https://www.youtube.com/iframe_api';

export const YOUTUBE_VIDEO_PLATFORM = 'YouTube';

/**
 * This function is used to provide the features to load a youtube video.
 * You need to return `playerTargetId` in the setup() in order to provide the
 * target element where the video should be rendered in.
 *
 * Example:
 * ```
 * <div :id="playerTargetId"></div>
 * ```
 *
 * After that you can implement either a handler of a click event or the `mounted` hook,
 * where you can call `loadVideo` to load the video.
 *
 * The necessary steps in `unMounted` are already implemented.
 * So no need to implement them in the component itself.
 *
 * @param video the video info
 * @returns features
 */
export const useYoutubeVideo = (
  video: Ref<StrapiMediaGalleryVideoItem>,
): VideoFeatures => {
  const isLoaded = ref<boolean>(false);
  const videoPlayer = ref<YT.Player | undefined>(undefined);
  const playerTargetId = computed(() => `yt-player-${video.value.id}`);

  /**
   * Initialize the video in the target element
   *
   * @param width target width of the video player
   * @param height target height of the video player
   */
  const initializeVideo = (width: Ref<number>, height: Ref<number>) => {
    videoPlayer.value = new YT.Player(playerTargetId.value, {
      width: Math.floor(width.value),
      height: Math.floor(height.value),
      videoId: video.value.videoID,
      events: {
        onReady: (event: YT.PlayerEvent) => {
          event.target.setVolume(10);
          event.target.playVideo();
        },
      },
    });

    isLoaded.value = true;
  };

  /**
   * Stops the video
   */
  const stopVideo = () => {
    if (videoPlayer.value) {
      videoPlayer.value.stopVideo();
    }
  };

  /**
   * Destroys the player
   */
  const destroyPlayer = () => {
    if (videoPlayer.value) {
      videoPlayer.value.destroy();
    }
  };

  const scriptExistsOnClient = (url: string) =>
    document.querySelectorAll(`script[src="${url}"]`).length > 0;

  /**
   * Manages setup of the iframe api and then initializes the video player once the Iframe API is ready.
   *
   * If the script tag is already available, just initialize the video player.
   *
   * @param width target width of the video player
   * @param height target height of the video player
   */
  const loadVideo = (width: Ref<number>, height: Ref<number>) => {
    if (scriptExistsOnClient(IFRAME_API_URL)) {
      initializeVideo(width, height);
    } else {
      const script = document.createElement('script');
      script.async = true;
      script.src = IFRAME_API_URL;
      document.head.appendChild(script);

      window.onYouTubeIframeAPIReady = () => {
        initializeVideo(width, height);
      };
    }
  };

  onUnmounted(() => {
    stopVideo();
    destroyPlayer();
  });

  return {
    playerTargetId,
    isLoaded,
    loadVideo,
    stopVideo,
    destroyPlayer,
  };
};

export default useYoutubeVideo;
