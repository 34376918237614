<template>
  <div>
    <!-- fullscreen button -->
    <button
      :aria-label="$t('frontend/fullscreen')"
      class="media-gallery__modal-toggle btn btn-sm position-absolute top-0 end-0 mt-4 me-4"
      type="button"
      @click="openModal"
    >
      <fa-icon class="text-rhino" :icon="faPlus" aria-hidden="true" />
    </button>

    <!-- actual modal -->
    <div
      ref="modalContainer"
      aria-hidden="true"
      class="modal fade"
      data-bs-keyboard="false"
      role="dialog"
    >
      <div
        class="media-gallery__modal-dialog modal-dialog modal-dialog-centered w-100"
      >
        <div
          v-if="currentItem != null"
          class="modal-content position-absolute h-100"
        >
          <div
            class="modal-body w-100 h-100 d-flex justify-content-center align-items-center"
          >
            <button
              class="media-gallery__modal-close position-absolute btn btn-sm"
              type="button"
              @click="closeModal"
            >
              <fa-icon class="text-rhino" :icon="faMinus" aria-hidden="true" />
            </button>
            <strapi-image
              v-if="currentItem.component === 'media-gallery.image'"
              :key="asGalleryImage(currentItem).image.id"
              :image="asGalleryImage(currentItem).image"
              alt-fallback="Slider Image"
              fluid
              image-class="media-gallery__modal-image w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import type { StrapiMediaGalleryItem } from '~/apollo/types/types';
import useBootstrapModal from '~/composables/bootstrap/useModal';
import { useStrapiMediaTypes } from './features';

export default defineComponent({
  props: {
    currentItem: {
      type: Object as PropType<StrapiMediaGalleryItem>,
      default: null,
    },
  },
  emits: ['modal-closed'],
  setup() {
    const modalContainer = ref<HTMLElement>(null);
    const { openModal, closeModal } = useBootstrapModal(modalContainer);

    return {
      faMinus,
      faPlus,

      modalContainer,
      openModal,
      closeModal,
      ...useStrapiMediaTypes(),
    };
  },
});
</script>

<style scoped lang="scss">
@import 'gportal-theme/scss/colors';

.media-gallery {
  &__modal {
    &-toggle {
      width: 1.875rem;
      height: 1.875rem;
      background-color: $gp-dark-mouse;
    }

    &-dialog {
      max-width: 98vw;
    }

    &-image {
      max-width: 1600px;
    }

    &-close {
      right: 40px;
      top: 40px;
      width: 1.875rem;
      height: 1.875rem;
      padding-bottom: 0.5rem;
      line-height: 25px;
      background-color: $gp-dark-mouse;
      color: $gp-mouse;
    }
  }
}
</style>
