import {
  StrapiMediaGalleryImageItem,
  StrapiMediaGalleryItem,
  StrapiMediaGalleryVideoItem,
} from '~/apollo/types/types';

export const useStrapiMediaTypes = () => {
  const asGalleryImage = (item: StrapiMediaGalleryItem) =>
    item as StrapiMediaGalleryImageItem;

  const asGalleryVideo = (item: StrapiMediaGalleryItem) =>
    item as StrapiMediaGalleryVideoItem;

  return {
    asGalleryImage,
    asGalleryVideo,
  };
};

export default {};
