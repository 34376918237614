<template>
  <div class="media-gallery">
    <div class="container">
      <h2 v-if="showTitle" class="media-gallery__title mb-0 mb-8">
        {{ component.title }}
      </h2>
      <div
        ref="carouselContainer"
        class="carousel slide carousel-fade mt-8 mb-4"
      >
        <div class="carousel-inner rounded">
          <!-- actual items -->
          <div
            v-for="(item, index) in component.items.filter(
              (item) => item != null,
            )"
            :key="item.id"
            :class="{ active: index === 0 }"
            class="carousel-item"
          >
            <media-gallery-video
              v-if="item.component === 'media-gallery.video'"
              ref="videoRefs"
              :is-active="currentItemIndex === index"
              :video="item"
            />
            <media-gallery-image
              v-if="item.component === 'media-gallery.image'"
              :image="item"
            />
          </div>
        </div>

        <!-- media gallery controls -->
        <button
          v-if="component.items.length > 1"
          aria-label="Previous"
          class="carousel-control-prev media-gallery__control-nav d-none d-lg-block top-50 start-0 translate-middle-y ms-4 rounded-circle"
          data-bs-slide="prev"
          data-bs-target="#media-gallery"
          type="button"
          @click="prevItem"
        >
          <fa-icon
            class="text-rhino lh-lg"
            :icon="faArrowLeft"
            aria-hidden="true"
          />
        </button>

        <button
          v-if="component.items.length > 1"
          aria-label="Next"
          class="carousel-control-next media-gallery__control-nav d-none d-lg-block top-50 end-0 translate-middle-y me-4 rounded-circle"
          data-bs-slide="next"
          data-bs-target="#media-gallery"
          type="button"
          @click="nextItem"
        >
          <fa-icon
            class="text-rhino lh-lg"
            :icon="faArrowRight"
            aria-hidden="true"
          />
        </button>

        <strapi-media-gallery-modal
          v-if="
            showFullscreenButton &&
            currentItem != null &&
            currentItem.component === 'media-gallery.image'
          "
          :current-item="currentItem"
        />
      </div>

      <strapi-media-gallery-thumbnails
        :current-item-index="currentItemIndex"
        :items="component.items"
        @thumbnail-clicked="setCurrentSlide"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import type { Carousel } from 'bootstrap';
import type { StrapiMediaGallery } from '~/apollo/types/types';
import { useStrapiMediaTypes } from './features';
import useBootstrapCarousel from '~/composables/bootstrap/useCarousel';

export default defineComponent({
  name: 'StrapiMediaGallery',
  props: {
    component: {
      type: Object as PropType<StrapiMediaGallery>,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const carouselContainer = ref<HTMLElement>();

    const currentItemIndex = ref<number>(0);
    const currentItem = computed(
      () => props.component.items[currentItemIndex.value],
    );

    const videoRefs = ref([]);

    const showFullscreenButton = ref<boolean>(false);
    const { width, height } = useWindowSize();
    const setShowFullscreenButton = () => {
      if (carouselContainer.value == null) return;

      showFullscreenButton.value =
        // the gallery container width with offset is smaller than the window AND
        carouselContainer.value.clientWidth + 50 < width.value &&
        // the gallery container height with offset is smaller than the window
        carouselContainer.value.clientHeight + 50 < height.value;
    };

    const watchScreenWidthHandler = watchThrottled(
      () => width.value,
      setShowFullscreenButton,
      {
        throttle: 500,
      },
    );

    const watchScreenHeightHandler = watchThrottled(
      () => height.value,
      setShowFullscreenButton,
      {
        throttle: 500,
      },
    );

    const setCurrentItemIndex = (event) => {
      const { to } = event as unknown as Carousel.Event;
      currentItemIndex.value = to;
    };

    const { carouselInstance, nextItem, prevItem } = useBootstrapCarousel(
      carouselContainer,
      {
        interval: false,
      },
      setCurrentItemIndex,
    );

    const setCurrentSlide = (index: number) => {
      carouselInstance.value.to(index);
      currentItemIndex.value = index;
    };

    onMounted(async () => {
      setShowFullscreenButton();
    });

    onUnmounted(() => {
      watchScreenWidthHandler();
      watchScreenHeightHandler();
    });

    return {
      faArrowRight,
      faArrowLeft,

      carouselContainer,
      videoRefs,
      currentItem,
      currentItemIndex,
      showFullscreenButton,
      nextItem,
      prevItem,
      setCurrentSlide,
      ...useStrapiMediaTypes(),
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors';

.media-gallery {
  .carousel {
    &-inner {
      z-index: 0;
    }

    &-control {
      &-prev,
      &-next {
        z-index: 0;
      }
    }
  }

  &__control {
    &-nav {
      width: 1.875rem;
      height: 1.875rem;
      background-color: $gp-dark-mouse;
    }
  }
}
</style>
