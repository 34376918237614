<template>
  <strapi-image
    :alt-fallback="`Slider Image ${image.id}`"
    :image="image.image"
    fluid
    image-class="d-block w-100"
  />
</template>

<script lang="ts">
import type { StrapiMediaGalleryImageItem } from '~/apollo/types/types';
import StrapiImage from '~/components/strapi/StrapiImage.vue';

export default defineComponent({
  name: 'MediaGalleryImage',
  components: {
    StrapiImage,
  },
  props: {
    image: {
      type: Object as PropType<StrapiMediaGalleryImageItem>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>
